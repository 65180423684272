<template>
  <div v-html="this.terms"></div>
</template>

<script>
export default {
  name: "Terms",

  computed: {
    config() {
      return this.$store.getters["config/config"] || {};
    },

    terms() {
      return (this.config && this.config.terms) || "";
    },
  },
};
</script>
